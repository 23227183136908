import service from '@/utils/request'; // @Tags EquityGoodsSpec
// @Summary 创建EquityGoodsSpec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoodsSpec true "创建EquityGoodsSpec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityGoodsSpec/createEquityGoodsSpec [post]

export var createEquityGoodsSpec = function createEquityGoodsSpec(data) {
  return service({
    url: "/equityGoodsSpec/createEquityGoodsSpec",
    method: 'post',
    data: data
  });
}; // @Tags EquityGoodsSpec
// @Summary 删除EquityGoodsSpec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoodsSpec true "删除EquityGoodsSpec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoodsSpec/deleteEquityGoodsSpec [delete]

export var deleteEquityGoodsSpec = function deleteEquityGoodsSpec(data) {
  return service({
    url: "/equityGoodsSpec/deleteEquityGoodsSpec",
    method: 'delete',
    data: data
  });
}; // @Tags EquityGoodsSpec
// @Summary 删除EquityGoodsSpec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除EquityGoodsSpec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /equityGoodsSpec/deleteEquityGoodsSpec [delete]

export var deleteEquityGoodsSpecByIds = function deleteEquityGoodsSpecByIds(data) {
  return service({
    url: "/equityGoodsSpec/deleteEquityGoodsSpecByIds",
    method: 'delete',
    data: data
  });
}; // @Tags EquityGoodsSpec
// @Summary 更新EquityGoodsSpec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoodsSpec true "更新EquityGoodsSpec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /equityGoodsSpec/updateEquityGoodsSpec [put]

export var updateEquityGoodsSpec = function updateEquityGoodsSpec(data) {
  return service({
    url: "/equityGoodsSpec/updateEquityGoodsSpec",
    method: 'put',
    data: data
  });
}; // @Tags EquityGoodsSpec
// @Summary 用id查询EquityGoodsSpec
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.EquityGoodsSpec true "用id查询EquityGoodsSpec"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /equityGoodsSpec/findEquityGoodsSpec [get]

export var findEquityGoodsSpec = function findEquityGoodsSpec(params) {
  return service({
    url: "/equityGoodsSpec/findEquityGoodsSpec",
    method: 'get',
    params: params
  });
}; // @Tags EquityGoodsSpec
// @Summary 分页获取EquityGoodsSpec列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取EquityGoodsSpec列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /equityGoodsSpec/getEquityGoodsSpecList [get]

export var getEquityGoodsSpecList = function getEquityGoodsSpecList(params) {
  return service({
    url: "/equityGoodsSpec/getEquityGoodsSpecList",
    method: 'get',
    params: params
  });
};